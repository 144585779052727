import React, { useReducer } from "react";
import {Reducer} from "./contextreducer";

const defaultState = {
    userInformation: {},
    all: [],
    fruitSalad: '',
    smoothieCustom: '',
    allOrders : [],
    openCheckout : false,
    isJuiceClicked: true,
    isEnergyClicked: false,
    isDetoxClicked: false,
    isWeightClicked: false,
    isSkinClicked: false,
    isWorkoutClicked: false,
    isInflammatoryClicked: false
};

export const CountContext = React.createContext(defaultState);

export const CountContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, defaultState);

    return (
        <CountContext.Provider value={{state, dispatch}}>
            {children}
        </CountContext.Provider>
    )
}