import React, {useEffect, useState} from "react";
import "./item.css";
import {CountContext} from "../../context/context"
import { useContext } from 'react';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import CancelIcon from '@mui/icons-material/Cancel';

const milks = [{
  id: 1,
  name: "Whole milk",
  price: [20, 20]
},
{
  id: 2,
  name: "Fat-free milk",
  price: [30, 30]
},
{
  id: 3,
  name: "Almond milk",
  price: [80, 100]
},
{
  id: 4,
  name: "Soy milk",
  price: [80, 100]
}
]


function Item(props){
   const {state, dispatch} = useContext(CountContext);
   const [clickedItem, setClickedItem] = useState ({});
   const [isClicked, setIsClicked] = useState(false);
   const [bottlesNum, setBottlesNum] = useState (1);
   const [checkoutInfo, setCheckoutInfo] = useState({id: '', color : props.color, font: props.font, name: props.name, ingredients: props.ingredients, size: 0, price: 0, bottles: 0, temperature: '', comments: '', type: 'Smoothie'});
   const [milk, setMilk] = useState(true);
   const [fatFree, setFatFree] = useState(false);
   const [almond, setAlmond] = useState(false);
   const [soy, setSoy] = useState(false);
   const [ginger, setGinger] = useState(false);
   const [cinnamon, setCinnamon] = useState(false);
   const [honey, setHoney] = useState(false);
   const [chosenMilk, setChosenMilk] = useState([]);
   const [halfMl, setHalfMl] = useState(false);
   const [threeQuarterMl, setThreeQuarterMl] = useState(false);
   const [roomTemp, setRoomTemp] = useState (false);
   const [chilledTemp, setChilledTemp] = useState (false);
   const [doneBG, setDoneBG] = useState(false);
   const [readDesc, setReadDesc] = useState(false);
   

   useEffect(()=>{
    setCheckoutInfo({...checkoutInfo, ingredients: [...props.ingredients, "Whole milk"], bottles: bottlesNum})
    
    let chosenMilkArr = milks.find((item) => {
      return item.id === 1
    });
    setChosenMilk(chosenMilkArr.price);
    

   }, [bottlesNum, props.ingredients])
  
   
  const handleClick = (e)=>{
    
    let currentItem = e.currentTarget;
    let title = currentItem.querySelector("#title").innerHTML;
    let type = currentItem.querySelector("#invisible-type").innerHTML;
    let currentItemObj = state.all.find((item)=>{
      return item.name === title && item.type === type
    });
  
    setClickedItem({...currentItemObj});
    setIsClicked(true);
    setCheckoutInfo({...checkoutInfo, id: state.allOrders.length})
  }
  
  const handleDescription = () => {
    setReadDesc(!readDesc);
  }

  const handleMilk = () => {
    setCheckoutInfo({...checkoutInfo, ingredients: [...props.ingredients, "Whole milk"]})
    
      setMilk(true);
      setFatFree(false);
      setAlmond(false);
      setSoy(false);

      let chosenMilkArr = milks.find((item) => {
        return item.id === 1
      });
      setChosenMilk(chosenMilkArr.price);
  };
  const handleFatFree = () => {
    setCheckoutInfo({...checkoutInfo, ingredients: [...props.ingredients, "Fat-free milk"]})

      setMilk(false);
      setFatFree(true);
      setAlmond(false);
      setSoy(false);
      
      let chosenMilkArr = milks.find((item) => {
        return item.id === 2
      });
      setChosenMilk(chosenMilkArr.price);
  }
  const handleAlmond = () => {
    setCheckoutInfo({...checkoutInfo, ingredients: [...props.ingredients, "Almond milk"]})

      setMilk(false);
      setFatFree(false);
      setAlmond(true);
      setSoy(false); 
      
      let chosenMilkArr = milks.find((item) => {
        return item.id === 3
      });
      setChosenMilk(chosenMilkArr.price);
  }
  const handleSoy = () => {
    setCheckoutInfo({...checkoutInfo, ingredients: [...props.ingredients, "Soy milk"]})

      setMilk(false);
      setFatFree(false);
      setAlmond(false);
      setSoy(true); 
      
      let chosenMilkArr = milks.find((item) => {
        return item.id === 4
      });
      setChosenMilk(chosenMilkArr.price);
  }

  const handleGinger = ()=>{
    setCheckoutInfo({...checkoutInfo, ingredients: [...props.ingredients, "Ginger"]});
     setGinger(true);
     setCinnamon(false);
     setHoney(false);

  };

  const handleCinnamon = () => {
    setCheckoutInfo({...checkoutInfo, ingredients: [...props.ingredients, "Cinnamon"]});
     setGinger(false);
     setCinnamon(true);
     setHoney(false);
  };

  const handleHoney = () => {
    setCheckoutInfo({...checkoutInfo, ingredients: [...props.ingredients, "Honey"]});
     setGinger(false);
     setCinnamon(false);
     setHoney(true);
  }

  const handle500 = (e)=>{
    
    setHalfMl(true);
    setThreeQuarterMl(false);
    const clickedQuantity = e.currentTarget;
    let clickedPrice = clickedQuantity.querySelector("#price").innerHTML;
    
    setCheckoutInfo({...checkoutInfo, size: 300, price: clickedPrice})
  }

  const handle750 = (e)=>{
    setHalfMl(false);
    setThreeQuarterMl(true);
    const clickedQuantity = e.currentTarget;
    let clickedPrice = clickedQuantity.querySelector("#price").innerHTML;
    
    setCheckoutInfo({...checkoutInfo, size: 500, price: clickedPrice})
  }


  const handleRoomTemp = (e) => {
    setRoomTemp(true);
    setChilledTemp(false);
    const roomTempBtn = e.currentTarget;
    let clickedTemp = roomTempBtn.querySelector('#roomtemp').innerHTML;
    
    setCheckoutInfo({...checkoutInfo, temperature: clickedTemp});
  }

  const handleChilled = (e) => {
    setRoomTemp(false);
    setChilledTemp(true);
    const chilledTempBtn = e.currentTarget;
    let clickedTemp = chilledTempBtn.querySelector('#chilledtemp').innerHTML;
    
    setCheckoutInfo({...checkoutInfo, temperature: clickedTemp});
  }

  const handleComment= (e) => {
    setCheckoutInfo({...checkoutInfo, comments: e.target.value});
  }
  

  const handleReduce = ()=>{
    setBottlesNum(bottlesNum - 1);
  }
  const handleIncrease = ()=>{
    setBottlesNum(bottlesNum + 1);

  }

  const handleOrderCount = (e) => {
    e.preventDefault();
    setDoneBG(true);
     dispatch({type: "edit-allOrders", payload: checkoutInfo});
     e.currentTarget.innerHTML = "DONE!!";
     setTimeout(() => {
     setIsClicked(false)
     setDoneBG(false);
     setHalfMl(false);
     setThreeQuarterMl(false);
     setMilk(false);
      setFatFree(false);
      setAlmond(false);
     setRoomTemp(false);
     setChilledTemp(false);
     setBottlesNum(1);
     setReadDesc(false);
     
     }, 500);
   
}


  const handleExit = () => {
    setIsClicked(false);
    setDoneBG(false);
     setHalfMl(false);
     setThreeQuarterMl(false);
     setMilk(false);
      setFatFree(false);
      setAlmond(false);
     setRoomTemp(false);
     setChilledTemp(false);
     setBottlesNum(1);
     setReadDesc(false);
  }

   return (
    <>
    <div className="item" onClick={handleClick}>
          <h3 id="title" style={{color: `${props.color}`, fontFamily: `${props.font}`}}>{props.name}</h3>
          <p id="invisible-type">{props.type}</p>
          <div className="item-list">
          {props.ingredients.map((item)=>{
            return <p>{item}</p>
          })}
          </div>

    </div>
    <div id="item-container" style={{display :`${isClicked ? `block` : `none`}`}}>
    <CancelIcon fontSize="large" className="cancelIcon" onClick={handleExit}/>
     <div className="item-info" >
     
     <h1 style={{color: `${clickedItem.color}`, fontFamily: `${clickedItem.font}`}}>{clickedItem.name}</h1>
     <div className="item-info-1">

     <div className="content" style={{height: `${readDesc ? `auto` : `${clickedItem.hasMilk ? `200px`: `250px`}`}`}}>
         <p>{clickedItem.description}</p>
         <div className="read-more" onClick={handleDescription}>
           <p>{readDesc ? "Show Less..." : "Show More..."}</p>
         </div>
     </div>
     <div className="content-desktop">
         <p>{clickedItem.description}</p>
     </div>
     <div className="item-info-2">
      <div className="milk-choice" style={{display : `${clickedItem.hasMilk ? `block` : `none`}`}}>
      <p className="selector" style={{display : `${clickedItem.hasMilk ? `block` : `none`}`}}>Select preferred milk to see PRICE!</p>
        <div className="milk-div">
        <div style={{backgroundColor: milk ? `${clickedItem.color}` : "#ffffff", border: `1.5px solid ${clickedItem.color}`}} onClick={handleMilk}>
          <p>Whole Milk</p>
        </div>
        <div style={{backgroundColor: fatFree ? `${clickedItem.color}` : "#ffffff", border: `1.5px solid ${clickedItem.color}`}} onClick={handleFatFree}>
          <p>Fat-free Milk</p>
        </div>
        <div style={{backgroundColor: almond ? `${clickedItem.color}` : "#ffffff", border: `1.5px solid ${clickedItem.color}`}} onClick={handleAlmond}>
          <p>Almond Milk</p>
        </div>
        <div style={{backgroundColor: soy ? `${clickedItem.color}` : "#ffffff", border: `1.5px solid ${clickedItem.color}`}} onClick={handleSoy}>
          <p>Soy Milk</p>
        </div>
        </div>
      </div>
      <div className="milk-choice" style={{display : `${clickedItem.juice ? `block` : `none`}`}}>
      <p className="selector" style={{display : `${clickedItem.juice ? `block` : `none`}`}}>Select an addin. Optional!</p>
        <div className="addin-div">
        <div style={{backgroundColor: ginger ? `${clickedItem.color}` : "#ffffff", border: `1.5px solid ${clickedItem.color}`}} onClick={handleGinger}>
          <p>Ginger</p>
        </div>
        <div style={{backgroundColor: cinnamon ? `${clickedItem.color}` : "#ffffff", border: `1.5px solid ${clickedItem.color}`}} onClick={handleCinnamon}>
          <p>Cinnamon</p>
        </div>
        <div style={{backgroundColor: honey ? `${clickedItem.color}` : "#ffffff", border: `1.5px solid ${clickedItem.color}`}} onClick={handleHoney}>
          <p>Honey</p>
        </div>
    
        </div>
      </div>
      <div className="pricing-div">
      <p className="temp">Select the size and price</p>
     <div className="pricing">
     <div style={{backgroundColor: halfMl ? `${clickedItem.color}` : "#ffffff", border: `1.5px solid ${clickedItem.color}`}} onClick={handle500}>
          <p><span id="size">300</span>ml</p>
          <h4>Ksh <span id="price">{clickedItem.hasMilk ? clickedItem.pricing?.[0] + chosenMilk[0] : clickedItem.pricing?.[0]}</span></h4>
      </div>
      <div style={{backgroundColor: threeQuarterMl ? `${clickedItem.color}` : "#ffffff", border: `1.5px solid ${clickedItem.color}`}} onClick={handle750}>
          <p><span id="size">500</span>ml</p>
          <h4>Ksh <span id="price">{clickedItem.hasMilk ? clickedItem.pricing?.[1] + chosenMilk[1] : clickedItem.pricing?.[1]}</span></h4>
      </div>
        
     </div>
     </div>

     <div className="temperature">
      <p className="temp">Select the temperature</p>
      <div className="tempDiv">
      <div style={{backgroundColor: roomTemp ? `${clickedItem.color}` : "#ffffff", border: `1.5px solid ${clickedItem.color}`}} onClick={handleRoomTemp}>
       <ThermostatIcon  fontSize="large"/>
        <p id="roomtemp">Room Temperature</p>
       </div>
       <div style={{backgroundColor: chilledTemp ? `${clickedItem.color}` : "#ffffff", border: `1.5px solid ${clickedItem.color}`}} onClick={handleChilled}>
        <AcUnitIcon  fontSize="large"/>
        <p id="chilledtemp">Chilled</p>
       </div>
      </div>
       
     </div>

     <div className="comment">
      <input style={{border: `1.5px solid ${clickedItem.color}`}} type="text" name="" id="comment" autocomplete="off" placeholder="Any instructions or comments? Optional!!" onChange={handleComment}/>
     </div>
    

      <div className="quantity-form" >
      <div>
         <span onClick={handleReduce}>-</span>
         <span>{bottlesNum}</span>
         <span onClick={handleIncrease}>+</span>
      </div>
     <button type="submit" style={{backgroundColor: doneBG ? "#CDCDCD" : `${clickedItem.color}`}} onClick={handleOrderCount}>{doneBG ? "DONE!!" : "ADD TO CART"}</button>
      </div>
     
     
     <div className="exit" style={{backgroundColor: `${clickedItem.color}`}} onClick={handleExit}>
         <p>EXIT</p>
     </div>
     </div>
     </div>
    </div>
 </div>
 
 </>      
   )
};

export default Item;