import React, {useState, useContext, useEffect, forwardRef} from "react";
import "./menu.css";
import Item from "../menu-item/item";
import { CountContext } from "../../context/context";


function Menu ({}, ref){
  const {state, dispatch} = useContext(CountContext);
  const [displayContent, setDisplayContent] = useState ([]);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 100; 

const onTouchStart = (e) => {
  setTouchEnd(null) 
  setTouchStart(e.targetTouches[0].clientX)
}

const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

const onTouchEnd = () => {
  if (!touchStart || !touchEnd) return
  const distance = touchStart - touchEnd
  const isLeftSwipe = distance > minSwipeDistance
  const isRightSwipe = distance < -minSwipeDistance
  if (isLeftSwipe){ 
    if(state.isJuiceClicked){
      dispatch({type: "energy"});

    let display = state.all?.filter((item)=>{
      return item.type === 'Energy-boost smoothie';
   });

   setDisplayContent(display);
    }else if (state.isEnergyClicked) {
      const element = document.getElementById("options-menu");
    element.scrollLeft = 120;
      dispatch({type: "detox"});

    let display = state.all?.filter((item)=>{
      return item.type === 'Detox smoothies';
   });

   setDisplayContent(display);
  }else if (state.isDetoxClicked) {
    const element = document.getElementById("options-menu");
    element.scrollLeft = 240;
    dispatch({type: "weight"});

    let display = state.all?.filter((item)=>{
      return item.type === 'Weight-loss smoothie';
   });

   setDisplayContent(display);
  }else if (state.isWeightClicked) {
    const element = document.getElementById("options-menu");
    element.scrollLeft = 360;
    dispatch({type: "skin"});

    let display = state.all?.filter((item)=>{
      return item.type === 'Skin-care smoothie';
   });

   setDisplayContent(display);
  }else if (state.isSkinClicked) {
    const element = document.getElementById("options-menu");
    element.scrollLeft = 480;
    dispatch({type: "workout"});

    let display = state.all?.filter((item)=>{
      return item.type === 'Post-Workout smoothie';
   });

   setDisplayContent(display);
  }else if (state.isWorkoutClicked) {
    dispatch({type: "inflammatory"});
    
    let display = state.all?.filter((item)=>{
      return item.type === 'Anti-inflammatory smoothie';
   });

   setDisplayContent(display);
  }
 
}

if (isRightSwipe){ 
  if (state.isEnergyClicked) {
    dispatch({type: "juice"});
    
    let display = state.all?.filter((item)=>{
      return item.type === 'Juices';
   });
  
   setDisplayContent(display);
  }else if (state.isDetoxClicked) {
    const element = document.getElementById("options-menu");
  element.scrollLeft = 0;
  dispatch({type: "energy"});
  
  let display = state.all?.filter((item)=>{
    return item.type === 'Energy-boost smoothie';
 });

 setDisplayContent(display);
}else if (state.isWeightClicked) {
  const element = document.getElementById("options-menu");
  element.scrollLeft = 120;
  dispatch({type: "detox"});

  let display = state.all?.filter((item)=>{
    return item.type === 'Detox smoothies';
 });

 setDisplayContent(display);
}else if (state.isSkinClicked) {
  const element = document.getElementById("options-menu");
  element.scrollLeft = 240;
  dispatch({type: "weight"});

  let display = state.all?.filter((item)=>{
    return item.type === 'Weight-loss smoothie';
 });

 setDisplayContent(display);
}else if (state.isWorkoutClicked) {
  const element = document.getElementById("options-menu");
  element.scrollLeft = 360;
  dispatch({type: "skin"});

  let display = state.all?.filter((item)=>{
    return item.type === 'Skin-care smoothie';
 });

 setDisplayContent(display);
}else if (state.isInflammatoryClicked) {
  const element = document.getElementById("options-menu");
  element.scrollLeft = 480;
  dispatch({type: "workout"});

  let display = state.all?.filter((item)=>{
    return item.type === 'Post-Workout smoothie';
 });

 setDisplayContent(display);
}

}


  
}

  useEffect(()=>{
    let display = state.all?.filter((item)=>{
      return item.type === 'Juices';
   });

   setDisplayContent(display);
  }, [state.all])

  const handleJuices = () => {
    dispatch({type: "juice"});
    
    let display = state.all?.filter((item)=>{
      return item.type === 'Juices';
   });

   setDisplayContent(display);
   };

   const handleEnergyboost = () => {
    dispatch({type: "energy"});
    let display = state.all?.filter((item)=>{
      return item.type === 'Energy-boost smoothie';
   });
   console.log(display);
   
   setDisplayContent(display);
   };


   const handleDetox =  () => {
    dispatch({type: "detox"});

    let display = state.all?.filter((item)=>{
      return item.type === 'Detox smoothies';
   });

   setDisplayContent(display);

   }

   const handleSkincare = () => {
    dispatch({type: "skin"});

    let display = state.all?.filter((item)=>{
      return item.type === 'Skin-care smoothie';
   });

   setDisplayContent(display);
   };

   const handleWeightloss = () => {
    dispatch({type: "weight"});
    let display = state.all?.filter((item)=>{
      return item.type === 'Weight-loss smoothie';
   });

   setDisplayContent(display);
   }


   const handleAntiinflammatory = () => {
    dispatch({type: "inflammatory"});
    
    let display = state.all?.filter((item)=>{
      return item.type === 'Anti-inflammatory smoothie';
   });

   setDisplayContent(display);
   };

   const handlePostworkout = () => {
    dispatch({type: "workout"});
    let display = state.all?.filter((item)=>{
      return item.type === 'Post-Workout smoothie';
   });

   setDisplayContent(display);
   };


  
  return (
    <div ref={ref} className="menu-div">
       <h1>Our Smoothie Menu</h1>
       <div className="menu-options" id="options-menu">
       <div onClick={handleJuices}>
        <h2 className={state.isJuiceClicked ? "active" : null} style={{width: "100px"}}>Juices</h2>
        </div>
         <div onClick={handleEnergyboost}>
        <h2 className={state.isEnergyClicked ? "active" : null} style={{width: "100px"}}>Fun Tasty<br></br>Energy</h2>
        </div>
        <div onClick={handleDetox}>
        <h2 className={state.isDetoxClicked ? "active" : null}>Detox Cleanse</h2>
        </div>
        <div onClick={handleWeightloss}>
        <h2 className={state.isWeightClicked ? "active" : null}>Weight<br></br>Management</h2>
        </div>
        <div onClick={handleSkincare}>
        <h2 className={state.isSkinClicked ? "active" : null} style={{padding: "10px 20px"}}>Skin Care</h2>
        </div>
        <div onClick={handlePostworkout}>
        <h2 className={state.isWorkoutClicked ? "active" : null}>Post-Workout</h2>
        </div>
        <div id="last-div" onClick={handleAntiinflammatory}>
        <h2 className={state.isInflammatoryClicked ? "active" : null}>Anti-inflammatories</h2>
        </div>
       </div>
       <div id="allList" className="menu-content" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
         {displayContent.map((item)=>{

          return (
            <Item 
               key={item.id}
               name = {item.name}
               color = {item.color}
               font = {item.font}
               ingredients = {item.ingredients}
               type = {item.type}
             />
          )

         })}
        
       </div>
      
    </div>
    
  )
}

export default forwardRef(Menu) ;